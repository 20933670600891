import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import DystenieHeader from '../Social_Network/DystenieHeader';
import { jwtDecode } from 'jwt-decode'; // Make sure this library is installed
import '../../style/Newsfeed/Feed.css'; // Updated CSS

interface PostDto {
  postId: number;
  content: string;
  dateCreated: string;
  username: string | null;
  profilePic: string | null;
  likesCount: number;
  mediaUrl?: string; // Add mediaUrl to the PostDto interface
  commentsCount: number;
}

interface CommentDto {
  id: number;
  content: string;
  dateCreated: string;
  username: string | null;
  profilePic: string | null;
}

interface DecodedToken {
  unique_name: string;
  userId: number;
  securityCode: string;
  isAdmin: string;
  isBusinessActivated: string;
  adminType: string | null;
  exp: number;
  [key: string]: any;
}

// Input sanitization function
const sanitizeInput = (input: string): string => {
  let sanitizedInput = input.trim();
  sanitizedInput = sanitizedInput.replace(/<[^>]*>/g, '');
  return sanitizedInput;
};

const Feed: React.FC = () => {
  const [posts, setPosts] = useState<PostDto[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postContent, setPostContent] = useState('');
  const [mediaFile, setMediaFile] = useState<File | null>(null); // State for media file
  const [decodedToken, setDecodedToken] = useState<DecodedToken | null>(null);
  const [loading, setLoading] = useState(true);

  // State to manage comments per post
  const [expandedPostId, setExpandedPostId] = useState<number | null>(null);
  const [comments, setComments] = useState<{ [postId: number]: CommentDto[] }>({});
  const [newCommentContent, setNewCommentContent] = useState<{ [postId: number]: string }>({});

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      try {
        const decoded = jwtDecode<DecodedToken>(token);
        setDecodedToken(decoded);
      } catch (error) {
        console.error('Failed to decode token:', error);
        toast.error('Invalid token. Please log in again.');
        localStorage.removeItem('jwtToken');
      }
    }

    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const headers: HeadersInit = {};
      const token = localStorage.getItem('jwtToken');
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Post`, { headers });

      if (response.ok) {
        const data = await response.json();
        setPosts(data);
      } else {
        const errorMessage = await response.text();
        toast.error(`Failed to fetch posts: ${errorMessage}`);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
      toast.error('An error occurred while fetching posts. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePost = async () => {
    const sanitizedContent = sanitizeInput(postContent);

    if (!sanitizedContent && !mediaFile) {
      toast.warn('Post content or media file is required.');
      return;
    }

    if (!decodedToken) {
      toast.error('You must be logged in to create a post.');
      return;
    }

    const { userId, securityCode } = decodedToken;

    try {
      const formData = new FormData();
      formData.append('content', sanitizedContent);
      formData.append('userId', userId.toString());
      formData.append('securityCode', securityCode);
      if (mediaFile) {
        formData.append('mediaFile', mediaFile);
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Post`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: formData, // Use FormData for file uploads
      });

      if (response.ok) {
        toast.success('Post created successfully!');
        setPostContent('');
        setMediaFile(null); // Clear the media file
        setIsModalOpen(false);
        fetchPosts(); // Refresh the post list
      } else {
        const errorMessage = await response.text();
        toast.error(`Failed to create post: ${errorMessage}`);
      }
    } catch (error) {
      console.error('Error creating post:', error);
      toast.error('An error occurred while creating the post. Please try again later.');
    }
  };

  const handleLikePost = async (postId: number) => {
    if (!decodedToken) {
      toast.warn('You must be logged in to like a post.');
      return;
    }

    const { userId, securityCode } = decodedToken;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Post/${postId}/like`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: JSON.stringify({
          userId,
          securityCode,
        }),
      });

      if (response.ok) {
        setPosts((prevPosts) =>
          prevPosts.map((post) => (post.postId === postId ? { ...post, likesCount: post.likesCount + 1 } : post))
        );
      } else {
        const errorMessage = await response.text();
        toast.error(`Failed to like post: ${errorMessage}`);
      }
    } catch (error) {
      console.error('Error liking post:', error);
      toast.error('An error occurred while liking the post. Please try again later.');
    }
  };

  // Fetch comments for a given post
  const fetchComments = async (postId: number) => {
    try {
      const headers: HeadersInit = {};
      const token = localStorage.getItem('jwtToken');
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Post/${postId}/comments`, { headers });

      if (response.ok) {
        const data = await response.json();
        setComments((prev) => ({ ...prev, [postId]: data }));
      } else {
        const errorMessage = await response.text();
        toast.error(`Failed to fetch comments: ${errorMessage}`);
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
      toast.error('An error occurred while fetching comments. Please try again later.');
    }
  };

  // Toggle the comment section for a post
  const toggleComments = (postId: number) => {
    if (expandedPostId === postId) {
      setExpandedPostId(null);
    } else {
      setExpandedPostId(postId);
      if (!comments[postId]) {
        fetchComments(postId);
      }
    }
  };

  // Handle adding a new comment
  const handleAddComment = async (postId: number) => {
    if (!decodedToken) {
      toast.warn('You must be logged in to comment.');
      return;
    }

    const { userId, securityCode } = decodedToken;
    const content = sanitizeInput(newCommentContent[postId] || '');

    if (!content) {
      toast.warn('Comment content cannot be empty.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Post/${postId}/comment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: JSON.stringify({
          userId,
          content,
          securityCode,
        }),
      });

      if (response.ok) {
        toast.success('Comment added successfully!');
        setNewCommentContent((prev) => ({ ...prev, [postId]: '' }));
        fetchComments(postId);
      } else {
        const errorMessage = await response.text();
        toast.error(`Failed to add comment: ${errorMessage}`);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('An error occurred while adding the comment. Please try again later.');
    }
  };

  return (
    <>
      <DystenieHeader />
      <div className="feed-container">
        <ToastContainer position="top-right" autoClose={3000} />
        {loading ? (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : (
          <div className="posts-list">
            {posts.length === 0 ? (
              <p className="no-posts-message">No posts available. Be the first to create one!</p>
            ) : (
              posts.map((post) => (
                <div key={post.postId} className="post-item">
                  <div className="post-header">
                    <img
                      className="profile-pic"
                      src={
                        post.profilePic?.startsWith('http')
                          ? post.profilePic
                          : `${process.env.REACT_APP_API_BASE_URL}${post.profilePic || '/images/default-profile.png'}`
                      }
                      alt={`Profile of ${post.username}`}
                    />
                    <div>
                      <span className="post-username">{post.username}</span>
                      <span className="post-date">{new Date(post.dateCreated).toLocaleString()}</span>
                    </div>
                  </div>

                  <div className="post-content">{post.content}</div>
                  {post.mediaUrl && (
  <div className="post-media">
    {post.mediaUrl.endsWith('.mp4') || post.mediaUrl.endsWith('.mov') ? (
      <video
        controls
        src={
          post.mediaUrl.startsWith('http')
            ? post.mediaUrl
            : `${process.env.REACT_APP_API_BASE_URL}${post.mediaUrl}`
        }
        className="media"
      />
    ) : (
      <img
        src={
          post.mediaUrl.startsWith('http')
            ? post.mediaUrl
            : `${process.env.REACT_APP_API_BASE_URL}${post.mediaUrl}`
        }
        alt="Post media"
        className="media"
      />
    )}
  </div>
)}
                  <div className="post-footer">
  <div className="like-section">
    <span className="post-likes">Likes: {post.likesCount}</span>
    <button
      className="like-button"
      onClick={() => handleLikePost(post.postId)}
      disabled={!decodedToken}
    >
      ❤️
    </button>
  </div>

  {/* Display the number of comments and a button to toggle comments */}
  <div className="comment-section">
    <button className="comment-toggle-button" onClick={() => toggleComments(post.postId)}>
      {expandedPostId === post.postId ? 'Hide Comments' : `Comments (${post.commentsCount})`}
    </button>
  </div>
</div>

                  {expandedPostId === post.postId && (
                    <div className="comments-section">
                      <div className="comments-list">
                        {comments[post.postId] && comments[post.postId].length > 0 ? (
                          comments[post.postId].map((comment) => (
                            <div key={comment.id} className="comment-item">
                              <div className="comment-header">
                                <img
                                  className="comment-profile-pic"
                                  src={
                                    comment.profilePic?.startsWith('http')
                                      ? comment.profilePic
                                      : `${process.env.REACT_APP_API_BASE_URL}${comment.profilePic || '/images/default-profile.png'}`
                                  }
                                  alt={`Profile of ${comment.username}`}
                                />
                                <span className="comment-username">{comment.username}</span>
                                <span className="comment-date">{new Date(comment.dateCreated).toLocaleString()}</span>
                              </div>
                              <div className="comment-content">{comment.content}</div>
                            </div>
                          ))
                        ) : (
                          <p className="no-comments">No comments yet.</p>
                        )}
                      </div>

                      <div className="add-comment-section">
  <div className="comment-input-container">
    <textarea
      className="comment-textarea"
      value={newCommentContent[post.postId] || ''}
      onChange={(e) =>
        setNewCommentContent((prev) => ({ ...prev, [post.postId]: e.target.value }))
      }
      placeholder={decodedToken ? "Write a comment..." : "Log in to comment"}
      disabled={!decodedToken}
      rows={3} // Adjust rows for better UX
    ></textarea>
    <button
      className="add-comment-button"
      onClick={() => handleAddComment(post.postId)}
      disabled={!decodedToken}
    >
      <span className="button-text">Add comment</span>
   
    </button>
  </div>
</div>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        )}
        {decodedToken && (
          <button className="create-post-button" onClick={() => setIsModalOpen(true)}>
            +
          </button>
        )}

{isModalOpen && (
  <div className="modal-overlay">
    <div className="modal-content">
      <h2>Create a New Post</h2>
      <textarea
        className="post-textarea"
        value={postContent}
        onChange={(e) => setPostContent(e.target.value)}
        placeholder="What's on your mind?"
        rows={4} // Adjusted rows for better UX
      ></textarea>

      {/* Modern File Chooser */}
      <div className="file-input-wrapper">
        <input
          type="file"
          id="mediaFile"
          className="file-input"
          accept="image/*, video/*"
          onChange={(e) => setMediaFile(e.target.files?.[0] || null)}
        />
        <label htmlFor="mediaFile" className="file-input-label">
          <span className="file-icon">📁</span>
          {mediaFile ? mediaFile.name : 'Choose a file (image or video)'}
        </label>
      </div>

      <div className="modal-actions">
        <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>
          Cancel
        </button>
        <button className="modal-submit" onClick={handleCreatePost}>
          Post
        </button>
      </div>
    </div>
  </div>
)}
      </div>
      <Footer />
    </>
  );
};

export default Feed;