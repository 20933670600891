import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Register/Login';
import Register from './components/Register/Register';
import Categories from './components/Categories/Categories';
import CategoryDetails from './components/Categories/CategoryDetails';
import Profile from './components/Profile/Profile';
import Newsfeed from './components/Newsfeed/Newsfeed';
import Cart from './components/Cart/Cart'; // Import the Cart component
import Wishlist from './components/Wishlist/Wishlist';
import LandingPage from './components/LandingPage/Landingpage';
import Sell from './components/Sell/Sell';
import ActivateBusinessAccount from './components/BusinessAccount/ActivateBusinessAccount';
import BusinessDashboard from './components/BusinessAccount/BusinessDashboard';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ForgotPassword/ResetPassword';
import MyOrders from './components/Orders/MyOrders';
import ManageSales from './components/BusinessAccount/Sales/ManageSales';
import ManageAddress from './components/Address/ManageAddress';
import CheckoutPart1 from './components/Checkout/CheckoutPart1';
import PaymentPage from './components/Payment/PaymentPage';
import ShippingManagement from './components/Shipping/ShippingManagement';
import ManagePayments from './components/Payment/ManagePayments';
import ProcessUserPayments from './components/Admin/Payments/ProcessUserPayments';
import Admin from './components/Admin/Admin';
import ProcessOrder from './components/Admin/Orders/ProcessOrder';
import PaymentDetails from './components/Admin/Orders/PaymentDetails';
import OrderNotifications from './components/Notifications/OrderNotification';
import CompletePayment from './components/Payment/CompletePayment';
import SubmitRequestMessage from './components/BusinessAccount/Messages/SubmitRequestMessage';
import UserPermissions from './components/Admin/User management/UserPermissions';
import ManageShipping from './components/Shipping/ManageShipping';
import UserManagement from './components/Admin/User management/UserManagemant';
import ManageAds from './components/Admin/Ads/ManageAds';
import GetOriginalPrice from './components/Admin/Sell/GetOriginalPrice';
import Statistics from './Statistics/Statistics';
import MultipleListing from './components/Sell/MultipleListing';
import BatchSell from './components/Sell/BatchSell';
import Announce from './components/Admin/Announce/Announce';
import Feed from './components/Newsfeed/Feed';
import NotAuthorized from './components/Not authorised/NotAuthorized';
import SearchResultsPage from './components/Header and Footer/SeatchSearchResultsPage';
import ReviewsPage from './components/Reviews/ReviewsPage';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import './App.css';

const Home = () => (
  <div>
    <h1>Welcome To The Second Hand Book Dealer.</h1>
  </div>
);

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/landingpage" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/landingpage" element={<LandingPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/categories/:id" element={<CategoryDetails />} />
        <Route path="/home" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/newsfeed" element={<Newsfeed />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/business-dashboard" element={<BusinessDashboard />} />
        <Route path="/activateBusinessAccount" element={<ActivateBusinessAccount />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/manage-sales" element={<ManageSales />} />
        <Route path="/manage-address" element={<ManageAddress />} />
        <Route path="/my-orders" element={<MyOrders />} />
        <Route path="/CheckoutPart1" element={<CheckoutPart1 />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/shipping-management" element={<ShippingManagement />} />
        <Route path="/manage-payments" element={<ManagePayments />} />
        <Route path="/admin-page" element={<Admin />} />
        <Route path="/process-user-payments" element={<ProcessUserPayments />} />
        <Route path="/process-order" element={<ProcessOrder />} />
        <Route path="/payment-details" element={<PaymentDetails />} />
        <Route path="/notifications" element={<OrderNotifications />} />
        <Route path="/complete-payment/:paymentId" element={<CompletePayment />} />
        <Route path="/SubmitRequestMessage" element={<SubmitRequestMessage />} />
        <Route path="/user-permissions" element={<UserPermissions />} />
        <Route path="/select-shipping" element={<ManageShipping />} />
        <Route path="/manage-users" element={<UserManagement />} />
        <Route path="/manage-ads" element={<ManageAds />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/get-original-price" element={<GetOriginalPrice />} />
        <Route path="/multiple-listing" element={<MultipleListing />} />
        <Route path="/batch-sell" element={<BatchSell />} />
        <Route path="/announce-details" element={<Announce />} />
        <Route path="/dystenie/feed" element={<Feed />} />
        <Route path="/not-authorized" element={<NotAuthorized />} />
        <Route path="/search-results" element={<SearchResultsPage />} />
        <Route path="/reviews" element={<ReviewsPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </Router>
  );
};

export default App;
