import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/LandingPage/LandingPage.css';

interface Ad {
  id: number;
  imageUrl: string;
  title?: string;
  description?: string;
  linkUrl?: string;
}

interface Review {
  userId: number;
  serviceId: number;
  fullName: string;
  rating: number;
  comment: string;
  dateCreated: string;
}

const LandingPage: React.FC = () => {
  const [ad, setAd] = useState<Ad | null>(null);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  // Fetch the most recent ad
  useEffect(() => {
    const fetchAd = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/Ads/latest`
        );
        const adData = response.data;

        if (adData.imageUrl && adData.imageUrl.startsWith('/')) {
          adData.imageUrl = `${process.env.REACT_APP_API_BASE_URL}${adData.imageUrl}`;
        }

        setAd(adData);
      } catch (error) {
        console.error('Error fetching ad:', error);
      }
    };

    fetchAd();
  }, []);

  // Check for cookie consent dismissal
useEffect(() => {
  const consentDismissed = localStorage.getItem('cookieConsentDismissed');
  if (!consentDismissed) {
    setShowCookieBanner(true); // Show the banner only if not previously dismissed
  }
}, []);


  // Fetch reviews
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get<Review[]>(
          `${process.env.REACT_APP_API_BASE_URL}/api/Review/general-feedback/all`
        );
        setReviews(response.data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, []);

  // Auto-slide reviews every 5 seconds
  useEffect(() => {
    if (reviews.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [reviews]);


  return (
    <div className="landing-page">
      <Header />

      <main>
        {/* Hero Section */}
        <section className="hero">
  <div className="hero-content">
  <h1 style={{ color: 'white' }}>Welcome to Books Haven</h1>
  <p style={{ color: 'white' }}>Discover your next favourite read in our vast collection of books</p>
    <div className="cta-container">
      <Link to="/categories" className="cta-button">
        Explore Books
      </Link>
      <Link to="/dystenie/feed" className="cta-button secondary">
        Explore Dystenie
      </Link>
    </div>
  </div>
</section>

        {/* Features Section */}
        <section className="features">
          <div className="feature">
            <i className="fas fa-book"></i>
            <h2>Vast Selection</h2>
            <p>Browse through thousands of titles across all genres</p>
          </div>
          <div className="feature">
            <i className="fas fa-truck"></i>
            <h2>Fast Delivery</h2>
            <p>Get your books delivered to your doorstep in no time</p>
          </div>
          <div className="feature">
            <i className="fas fa-user-friends"></i>
            <h2>Community</h2>
            <p>Join book clubs and discuss your favorite reads</p>
          </div>
        </section>

        {/* Advertisement Section */}
        <section className="ad-space">
          <div className="ad-container">
            {ad ? (
              <div className="ad-content">
                <p className="ad-label">Advertisement</p>
                {ad.linkUrl ? (
                  <a
                    href={ad.linkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ad-link"
                  >
                    {ad.imageUrl ? (
                      <img
                        src={ad.imageUrl}
                        alt={ad.title || 'Advertisement'}
                        className="ad-image"
                      />
                    ) : (
                      <div className="ad-placeholder">
                        <p>No image available</p>
                      </div>
                    )}
                  </a>
                ) : ad.imageUrl ? (
                  <img
                    src={ad.imageUrl}
                    alt={ad.title || 'Advertisement'}
                    className="ad-image"
                  />
                ) : (
                  <div className="ad-placeholder">
                    <p>No image available</p>
                  </div>
                )}
                {ad.title && <h3 className="ad-title">{ad.title}</h3>}
                {ad.description && <p className="ad-description">{ad.description}</p>}
              </div>
            ) : (
              <p className="ad-label">Advertisement</p>
            )}
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2>What Our Customers Say</h2>
          <div className="testimonial-container">
            {reviews.length > 0 ? (
              <div className="testimonial">
                <p>"{reviews[currentIndex].comment}"</p>
                <cite>- {reviews[currentIndex].fullName}</cite>
                <div className="rating">
                  {Array.from({ length: reviews[currentIndex].rating }, (_, i) => (
                    <span key={i}>&#9733;</span> // Unicode for a solid star
                  ))}
                  {Array.from({ length: 5 - reviews[currentIndex].rating }, (_, i) => (
                    <span key={i}>&#9734;</span> // Unicode for an empty star
                  ))}
                </div>
              </div>
            ) : (
              <p>No reviews available yet. Be the first to leave feedback!</p>
            )}
          </div>
        </section>
      </main>

      <Footer />

      {showCookieBanner && (
  <div className="cookie-banner">
    <button
      className="close-banner-button"
      onClick={() => {
        localStorage.setItem('cookieConsentDismissed', 'true'); // Save dismissal status
        setShowCookieBanner(false); // Hide the banner
      }}
    >
      &times; {/* Unicode for the "X" symbol */}
    </button>
    <p>
      By using this website, you agree to our{' '}
      <Link to="/terms-and-conditions" className="terms-link">
        Terms and Conditions
      </Link>{' '}
      and our use of cookies to enhance your experience.
    </p>
  </div>
)}

    </div>
  );
};

export default LandingPage;
