// src/components/Header and Footer/DystenieHeader.tsx

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faSearch,
  faShoppingCart,
  faSignOutAlt,
  faUser,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import "../../style/Social_Network/DystenieHeader.css";

interface Category {
  id: number;
  name: string;
  description: string;
  genre: string;
}

const DystenieHeader: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Category[]>([]);
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem("jwtToken");

  useEffect(() => {
    if (searchTerm) {
      const fetchSearchResults = async () => {
        if (!searchTerm.trim()) {
          setSearchResults([]);
          return;
        }

        try {
          const url = `${process.env.REACT_APP_API_BASE_URL}/api/Category/search?name=${encodeURIComponent(searchTerm)}`;
          const response = await fetch(url);

          if (!response.ok) {
            if (response.status === 404) {
              setSearchResults([]); // No matches found
            } else {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
          } else {
            const data = await response.json();
            setSearchResults(data);
          }
        } catch (error) {
          console.error("Error fetching search results:", error);
          setSearchResults([]);
        }
      };

      const timer = setTimeout(fetchSearchResults, 300);
      return () => clearTimeout(timer);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userId");
    navigate("/login");
  };

  return (
    <header className="dystenie-header">
      <div className="dystenie-header-content">
        <Logo />
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          searchResults={searchResults}
        />
        <div className="dystenie-nav-actions-container">
          <Navigation />
          <HeaderActions isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
        </div>
      </div>
    </header>
  );
};

const Logo: React.FC = () => (
  <div className="dystenie-logo">
    <Link to="/landingpage" style={{ color: "white", textDecoration: "none", fontWeight: "bold", fontSize: "1.5rem" }}>
      Dystenie
    </Link>
  </div>
);

const Navigation: React.FC = () => (
  <nav className="dystenie-nav">
    <Link to="/categories" title="Categories">
      <FontAwesomeIcon icon={faShoppingBasket} />
    </Link>
    <Link to="/cart" title="Cart">
      <FontAwesomeIcon icon={faShoppingCart} />
    </Link>
    <Link to="/wishlist" title="Wishlist">
      <FontAwesomeIcon icon={faHeart} />
    </Link>
    <Link to="/profile" title="Profile">
      <FontAwesomeIcon icon={faUser} />
    </Link>
  </nav>
);

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  searchResults: Category[];
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  setSearchTerm,
  searchResults,
}) => {
  const navigate = useNavigate();

  const handleSearchClick = () => {
    if (searchTerm.trim()) {
      navigate(`/search-results?searchTerm=${encodeURIComponent(searchTerm)}`);
    }
  };

  return (
    <div className="dystenie-search">
      <input
        type="text"
        placeholder="Search posts ..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button aria-label="Search" onClick={handleSearchClick}>
        <FontAwesomeIcon icon={faSearch} />
      </button>

      {searchResults.length > 0 && (
        <div className="dystenie-search-results">
          {searchResults.map((category) => (
            <Link
              to={`/categories/${category.id}`}
              key={category.id}
              className="dystenie-search-result-item"
            >
              <h6>{category.name}</h6>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

interface HeaderActionsProps {
  isLoggedIn: boolean;
  handleLogout: () => void;
}

const HeaderActions: React.FC<HeaderActionsProps> = ({
  isLoggedIn,
  handleLogout,
}) => (
  <div className="dystenie-header-actions">
    {isLoggedIn ? (
      <button onClick={handleLogout} className="dystenie-logout-button">
        <FontAwesomeIcon icon={faSignOutAlt} /> Logout
      </button>
    ) : (
      <Link to="/login" className="dystenie-login-button">
        <FontAwesomeIcon icon={faUser} /> Login
      </Link>
    )}
  </div>
);

export default DystenieHeader;