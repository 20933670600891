import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faHeart } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from "jwt-decode";
import '../../style/Categories/CategoryDetails.css';

interface Category {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  genre: string;
  price: number;
  condition: string;
}

const CategoryDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [category, setCategory] = useState<Category | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!id) {
      setError('No category ID provided.');
      setLoading(false);
      return;
    }

    const fetchCategoryDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Category/${id}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch category details. Status: ${response.status}`);
        }
        const data = await response.json();
        setCategory(data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryDetails();
  }, [id]);

  const getUserDataFromToken = (): { userId: string | null; securityCode: string | null } => {
    const token = localStorage.getItem('jwtToken');
    if (!token) return { userId: null, securityCode: null };

    try {
      const decodedToken = jwtDecode<{ userId: string; securityCode: string }>(token);
      return {
        userId: decodedToken.userId,
        securityCode: decodedToken.securityCode,
      };
    } catch (error) {
      console.error('Failed to decode token:', error);
      return { userId: null, securityCode: null };
    }
  };

  const addToCart = async () => {
    const { userId, securityCode } = getUserDataFromToken();
    const productId = category?.id;
    const quantity = 1;

    if (!userId || !securityCode || !productId) {
      toast.error('User is not logged in or product ID is missing.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/Cart/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: JSON.stringify({ userId, productId, quantity, securityCode }),
      });

      if (!response.ok) {
        throw new Error(`Failed to add to cart. Status: ${response.status}`);
      }

      toast.success('Added to cart successfully!');
    } catch (err) {
      console.error('Error adding to cart:', err);
      toast.error('Failed to add to cart.');
    }
  };

  const addToWishlist = async () => {
    const { userId, securityCode } = getUserDataFromToken();
    const productId = category?.id;

    if (!userId || !securityCode || !productId) {
      toast.error('User is not logged in or product ID is missing.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/Wishlist/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: JSON.stringify({ userId, productId, securityCode }),
      });

      if (!response.ok) {
        throw new Error(`Failed to add to wishlist. Status: ${response.status}`);
      }

      toast.success('Added to wishlist successfully!');
    } catch (err) {
      console.error('Error adding to wishlist:', err);
      toast.error('Failed to add to wishlist.');
    }
  };

  if (loading) {
    return (
      <>
        <Header />
        <div className="category-details-loading-container">
          <div className="category-details-loading-spinner"></div>
          <p>Loading book details...</p>
        </div>
        <Footer />
      </>
    );
  }

  if (error) {
    return <div>Error fetching category details: {error}</div>;
  }

  if (!category) {
    return <div>No category found.</div>;
  }

  return (
    <>
      <Header />
      <ToastContainer />
      <div className="category-details-container">
        <div className="category-details-info-container">
          <h1>{category.name}</h1>
          <img
            src={
              category.imageUrl.startsWith('/')
                ? `${process.env.REACT_APP_API_BASE_URL}${category.imageUrl}`
                : category.imageUrl
            }
            alt={category.name}
            className="category-details-image"
            onError={(e) => {
              e.currentTarget.src =
                'https://via.placeholder.com/400x200?text=Image+Not+Available';
              e.currentTarget.alt = 'Placeholder image';
            }}
          />
          <p
  className="category-details-info"
  dangerouslySetInnerHTML={{ __html: category.description }}
></p>

          <p className="category-details-info">
            <strong>Condition:</strong> <strong>{category.condition || 'Not Specified'}</strong>
          </p>
          <p className="category-details-info">
            <strong>Genre:</strong> {category.genre}
          </p>
          <p className="category-details-info">
            <strong>Price:</strong> R{category.price}
          </p>
        </div>

        <div className="button-container">
          {/* Move NEW sticker here, above the buttons */}
          {category.condition === "New" && (
            <div className="new-sticker">NEW</div>
          )}

          <button className="button button-cart" onClick={addToCart}>
            Add to Cart <FontAwesomeIcon icon={faShoppingCart} />
          </button>
          <button className="button button-wishlist" onClick={addToWishlist}>
            Add to Wishlist <FontAwesomeIcon icon={faHeart} />
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CategoryDetails;
